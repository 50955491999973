import BannerThreePicturesLink from '../../../components/BannerThreePicturesLink'
import Header from '../../../components/Header'

import './index.scss'

function About() {
	const pageId = 32

	return (
		<div className="SOUKIASSIAN-About">
			<Header
				type="finalPage"
				title="C'est le moment d'utiliser votre potentiel véritable."
			/>
			<BannerThreePicturesLink
				picture1={{
					title: 'CHRISTOPHE SOUKIASSIAN',
					pageId: pageId,
					entityId: 1,
					link: '/Christophe-Soukiassian',
					flipped: true,
				}}
				picture2={{
					title: 'LA MÉTHODE',
					pageId: pageId,
					entityId: 2,
					link: '/method',
				}}
				picture3={{
					title: "L'ÉCOLE",
					pageId: pageId,
					entityId: 3,
					link: '/lcc-school',
				}}
			/>
		</div>
	)
}

export default About
