import Header from '../../../components/Header'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'

function LifeCoaching() {
	const pageId = 33

	return (
		<div className="SOUKIASSIAN-LifeCoaching">
			<Header type="finalPage" title="COACHER SA VIE" />
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="SÉANCE"
				subTitle="COMMUNICATION CONSCIENTE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={1}
					link="/session-life-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COMMUNICATION CONSCIENTE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={2}
					link="/training-life-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
		</div>
	)
}

export default LifeCoaching
