import { useEffect } from 'react'

import Header from '../../components/Header'
import BannerText from '../../components/BannerText'
import BannerThreePicturesLink from '../../components/BannerThreePicturesLink'
import Button from '../../components/Button'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import Footer from '../../components/Footer'
import PictureTextLink from '../../components/PictureTextLink'
import TestimoniesHome from '../../components/TestimoniesHome'

import './index.scss'

function Home() {
	const buttonStageId = 'buttonHomeStage'
	const pageId = 2

	useEffect(() => {
		const buttonStageElement = document.getElementById(buttonStageId)

		if (typeof IntersectionObserver !== 'undefined') {
			const observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					buttonStageElement.classList.add(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				} else {
					buttonStageElement.classList.remove(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				}
			})

			observer.observe(buttonStageElement)

			return () => observer.disconnect()
		} else {
			buttonStageElement.classList.add(
				'SOUKIASSIAN-Home-ButtonCTA-stage-viewedIsNotAvailable',
			)
		}
	}, [])

	return (
		<div className="SOUKIASSIAN-Home">
			<Header
				type="home"
				pageId={pageId}
				entityId={1}
				title="Découvrez une séance offerte !"
				subTitle={
					<>
						<br />
						Osez une vie authentique.
						<br />
						Transformez vos difficultés.
						<br />
						Brillez en pleine conscience.
					</>
				}
				/*buttonText="NOUS REJOINDRE LORS D'UN ÉVÉNEMENT"*/
				buttonText="CONTACTEZ-NOUS"
			/>

			<BannerText
				className="SOUKIASSIAN-Home-headerSubTitle"
				title={
					<>
						<span>Développez le potentiel véritable</span>
					</>
				}
				textNoBg
			>
				Cette année, reconnectons-nous à la véritable essence de l'être
				humain.
				<br />
				<br />
				Avançons ensemble, avec bienveillance, vers la réalisation de
				vos projets personnels et professionnels.
				<br />
				<br />
				Construisons un quotidien harmonieux et aidons les autres à en
				faire de même.
			</BannerText>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES SÉANCES ET FORMATIONS POUR TOUS"
				subTitle="RÉALISEZ-VOUS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'COACHER SA VIE',
						pageId: pageId,
						entityId: 2,
						link: '/life-coaching',
					}}
					picture2={{
						title: 'DEVENIR COACH',
						pageId: pageId,
						entityId: 3,
						link: '/become-coach',
					}}
					picture3={{
						title: "COACHING D'ENTREPRISE",
						pageId: pageId,
						entityId: 4,
						link: '/business-coaching',
						flipped: true,
					}}
					bordered
				/>
			</HarmoniousTitle>

			<BannerText>
				Découvrez-vous en explorant de nouvelles perspectives avec
				l'École L.C.C. ©
				<br />
				Ses séances, stages et formations vous guideront pour
				progresser,
				<br />
				comblant ainsi l'écart entre votre situation actuelle et vos
				aspirations.
			</BannerText>

			<Button
				id={buttonStageId}
				className="SOUKIASSIAN-Home-ButtonCTA SOUKIASSIAN-Home-ButtonCTA-stage col-xxs-11 col-xs-11 col-6"
				link="/stages"
			>
				DÉCOUVREZ NOS STAGES
			</Button>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="LES FORMATIONS DE FORMATEURS"
				subTitle="ACCOMPLISSEZ-VOUS"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					title="DEVENEZ FORMATEUR"
					pageId={pageId}
					entityId={5}
					link="/trainer-trainings"
					bordered
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>

			<TestimoniesHome
				pageId={pageId}
				entityId={6}
				entityIdList={[7, 8, 9]}
			/>

			<Footer />
		</div>
	)
}

export default Home
