import Header from '../../../components/Header'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'

function BusinessCoaching() {
	const pageId = 35

	return (
		<div className="SOUKIASSIAN-BusinessCoaching">
			<Header type="finalPage" title="COACHING D'ENTREPRISE" />
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="SÉANCE"
				subTitle="COACHING PROFESSIONNEL"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={1}
					link="/session-business-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COMMUNICATION D'ÉCOUTE ET D'EXPRESSION NON BLESSANTES"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={2}
					link="/training-business-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
		</div>
	)
}

export default BusinessCoaching
